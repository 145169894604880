<template>
  <div class="row">
    
    <div class="col-lg-8 m-auto">
                <v-card :title="$t('auth.verify_email')">

                  <v-card-text v-if="success">
                    <v-alert outlined prominent border="left"  type="success">
                      <div class="title"> {{ $t('auth.event_accepted') }}</div>
                    </v-alert>
                  </v-card-text>
                  <v-card-text v-else-if="error">
                    <div class="alert alert-danger" role="alert">
                      <div class="title">{{ $t('auth.event_not_accepted') }}</div>
                      <div>{{ $t('auth.event_not_accepted_info') }}</div>
                    </div>
                  </v-card-text>
                    <v-card-actions>

                      <router-link v-if="activation_code" class="tw-text-black" :to="{ name: 'account.active', params: { code: activation_code }}">
                        <span class="tw-text-gray-n3">{{ $t('auth.your_account_is_ready') }}</span> {{ $t('auth.signin') }}
                      </router-link>
                      <router-link v-else class="tw-text-black" :to="{ name: 'login' }"><span class="tw-text-gray-n3">{{ $t('auth.already_have_account') }}</span> {{ $t('auth.signin') }}</router-link>

                    </v-card-actions>
                </v-card>

    </div>
  </div>
</template>

<script>

import i18n from '@/i18n'

const qs = (params) => Object.keys(params).map(key => `${key}=${params[key]}`).join('&')

export default {
  //middleware: 'guest',

  metaInfo () {
    return { title: i18n.t('auth.verify_email') }
  },


  async beforeRouteEnter (to, from, next) {
    try {
      await this.$http.get('sanctum/csrf-cookie');
      const { data } = await this.$http.post(`/api/event/invitations/accept/${to.params.id}?${qs(to.query)}`)
      next(vm => { 
          vm.success = data.status;
          vm.activation_code = data.activation_code;
        })
    } catch (e) {
      next(vm => { vm.error = e.data.status })
    }
  },

  data: () => ({
    error: '',
    success: '',
    activation_code: null
  })
}
</script>
